import Choices from 'choices.js';

const element = document.querySelectorAll('.js-select-input');
if (element.length > 0) {
    element.forEach(function (node) {
        let choices = new Choices(node, {
            placeholder: true,
            searchEnabled: false,
            loadingText: '',
            itemSelectText: '',
            allowHTML: true,
            shouldSort: false,
            classNames: {
                containerOuter: 'choices form-select',
            },
        });
    });
}
